@import "variables";
@import "fonts";

body {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  color: #333;
  background: url(../img/back_grey.png) center repeat;
  padding: 10px 0;

  @media (max-width: 576px) {
    padding: 0;
  }
}

a {
  color: $linkColor;
  text-decoration: underline;
  cursor: pointer;

  &:hover, &:focus, &:active {
    color: $linkColor;
  }

  &.btn {
    text-decoration: none;
  }
}

h1 {
  font-family: 'Bebas Neue Book', sans-serif;
  font-size: 26px;
  font-weight: bold;
  margin: 0 0 20px;
}

h2 {
  font-family: 'Bebas Neue Book', sans-serif;
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}

.container {
  background: #ffffff;
}

.button {
  height: 26px;
  font-size: 16px;
  line-height: 20px;
  padding: 2px 10px;
  color: #ffffff;
  background: $linkColor;
  border: 1px solid $linkColor;
  vertical-align: middle;
}

a.button {
  text-decoration: none;

  &:hover {
    color: #ffffff;
  }
}

ul.pagination {
  li {
    padding: 2px 4px;

    a, span {
      font-weight: bold;
      text-decoration: none;
    }
  }
}

.has-error {
  input, textarea, select {
    border-color: #dc3545;
  }
}

[data-validate-for].visible {
  color: #dc3545;
  font-size: 80%;
}

header {
  .navbar-toggler {
    position: absolute;
    top: 20px;
    z-index: 1;
    color: #ffffff;
    font-size: 30px;
  }

  .main-header {
    margin-bottom: 20px;
    text-align: center;
    background: #535F6B;

    a {
      font-family: 'PragmaticaLightC', sans-serif;
      font-weight: bold;
      text-decoration: none;
      font-size: 36px;
      line-height: 60px;
      letter-spacing: 0.02em;

      @media (max-width: 576px) {
        line-height: 40px;
      }
    }
  }

  .search {
    text-align: right;

    input {
      width: 200px;
      height: 26px;
      margin-right: -4px;
      padding: 2px;
      color: #333;
      background: #FFF;
      border: 1px solid $linkColor;
      vertical-align: middle;
    }
  }

  .logos {
    img {
      margin: 0 5px;
      vertical-align: middle;
      height: 30px;
    }
  }
}

footer {
  .copyright {
    padding-bottom: 20px;
  }
}

.divider {
  width: 100%;
  margin-top: 20px;
  padding-bottom: 20px;
  height: 1px;
  border-top: 1px dashed #ccc;
}

.menu {
  ul {
    margin: 0;
    padding: 10px;
    list-style-type: none;
    color: #FFF;
    background: #535F6B;
    box-shadow: 10px 0 6px -8px rgba(0, 0, 0, 0.6);

    li {
      padding: 0;
      margin: 5px 0 0;

      a {
        letter-spacing: 0.02em;
        font-weight: bold;
        color: #FFF;
        text-decoration: none;
        text-transform: uppercase;
        min-height: 33px;
        display: block;
        background: url("../img/line.png") left bottom no-repeat;
        padding: 5px 0;
      }

      ul {
        display: none;
        box-shadow: none;

        li {
          padding-left: 20px;

          a {
            font-size: 12px;
          }
        }
      }

      &.active, &.child-active {
        ul {
          display: block;
        }
      }

      &.active, &:hover {
        > a {
          color: $linkColor;
          text-decoration: none;
        }
      }
    }
  }
}

.box {
  color: #ffffff;
  background: #535F6B;
  padding: 10px;
  //box-shadow: 0 10px 6px -8px rgba(0, 0, 0, 0.6);

  a {
    color: #ffffff;
  }
}

.home-categories {
  text-align: center;

  a {
    text-decoration: none;
  }
}

table.price {
  i {
    transition: color 0.3s;
  }

  i.fa-cart-plus {
    color: $linkColor;
  }

  i.fa-check {
    color: green;
  }
}

/* Моргание */
.blink {
  animation: blink 1s step-end infinite;
}

@keyframes blink {
  67% {
    opacity: 0
  }
}

/* Стили для Media Manager */
img.fr-dii.fr-fir {
  float: right;
  margin: 5px 0 5px 5px;
}

img.fr-dib {
  display: block;
  float: none;
  margin: 5px auto;
}