@charset "UTF-8";
@import "https://fonts.googleapis.com/css?family=Open+Sans:400,400i,700,700i&subset=cyrillic";
@font-face {
  font-family: "Bebas Neue Book";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/bebasneuebook.eot") format("embedded-opentype"), url("../fonts/bebasneuebook.woff") format("woff"), url("../fonts/bebasneuebook.svg") format("svg"); }

@font-face {
  font-family: 'PragmaticaLightC';
  src: url("../fonts/hinted-PragmaticaLightC-Bold.eot");
  src: url("../fonts/hinted-PragmaticaLightC-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/hinted-PragmaticaLightC-Bold.woff") format("woff"), url("../fonts/hinted-PragmaticaLightC-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'PragmaticaLightC';
  src: url("../fonts/hinted-PragmaticaLightC.eot");
  src: url("../fonts/hinted-PragmaticaLightC.eot?#iefix") format("embedded-opentype"), url("../fonts/hinted-PragmaticaLightC.woff") format("woff"), url("../fonts/hinted-PragmaticaLightC.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

body {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  color: #333;
  background: url(../img/back_grey.png) center repeat;
  padding: 10px 0; }
  @media (max-width: 576px) {
    body {
      padding: 0; } }

a {
  color: #F39A33;
  text-decoration: underline;
  cursor: pointer; }
  a:hover, a:focus, a:active {
    color: #F39A33; }
  a.btn {
    text-decoration: none; }

h1 {
  font-family: 'Bebas Neue Book', sans-serif;
  font-size: 26px;
  font-weight: bold;
  margin: 0 0 20px; }

h2 {
  font-family: 'Bebas Neue Book', sans-serif;
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px; }

.container {
  background: #ffffff; }

.button {
  height: 26px;
  font-size: 16px;
  line-height: 20px;
  padding: 2px 10px;
  color: #ffffff;
  background: #F39A33;
  border: 1px solid #F39A33;
  vertical-align: middle; }

a.button {
  text-decoration: none; }
  a.button:hover {
    color: #ffffff; }

ul.pagination li {
  padding: 2px 4px; }
  ul.pagination li a, ul.pagination li span {
    font-weight: bold;
    text-decoration: none; }

.has-error input, .has-error textarea, .has-error select {
  border-color: #dc3545; }

[data-validate-for].visible {
  color: #dc3545;
  font-size: 80%; }

header .navbar-toggler {
  position: absolute;
  top: 20px;
  z-index: 1;
  color: #ffffff;
  font-size: 30px; }

header .main-header {
  margin-bottom: 20px;
  text-align: center;
  background: #535F6B; }
  header .main-header a {
    font-family: 'PragmaticaLightC', sans-serif;
    font-weight: bold;
    text-decoration: none;
    font-size: 36px;
    line-height: 60px;
    letter-spacing: 0.02em; }
    @media (max-width: 576px) {
      header .main-header a {
        line-height: 40px; } }

header .search {
  text-align: right; }
  header .search input {
    width: 200px;
    height: 26px;
    margin-right: -4px;
    padding: 2px;
    color: #333;
    background: #FFF;
    border: 1px solid #F39A33;
    vertical-align: middle; }

header .logos img {
  margin: 0 5px;
  vertical-align: middle;
  height: 30px; }

footer .copyright {
  padding-bottom: 20px; }

.divider {
  width: 100%;
  margin-top: 20px;
  padding-bottom: 20px;
  height: 1px;
  border-top: 1px dashed #ccc; }

.menu ul {
  margin: 0;
  padding: 10px;
  list-style-type: none;
  color: #FFF;
  background: #535F6B;
  box-shadow: 10px 0 6px -8px rgba(0, 0, 0, 0.6); }
  .menu ul li {
    padding: 0;
    margin: 5px 0 0; }
    .menu ul li a {
      letter-spacing: 0.02em;
      font-weight: bold;
      color: #FFF;
      text-decoration: none;
      text-transform: uppercase;
      min-height: 33px;
      display: block;
      background: url("../img/line.png") left bottom no-repeat;
      padding: 5px 0; }
    .menu ul li ul {
      display: none;
      box-shadow: none; }
      .menu ul li ul li {
        padding-left: 20px; }
        .menu ul li ul li a {
          font-size: 12px; }
    .menu ul li.active ul, .menu ul li.child-active ul {
      display: block; }
    .menu ul li.active > a, .menu ul li:hover > a {
      color: #F39A33;
      text-decoration: none; }

.box {
  color: #ffffff;
  background: #535F6B;
  padding: 10px; }
  .box a {
    color: #ffffff; }

.home-categories {
  text-align: center; }
  .home-categories a {
    text-decoration: none; }

table.price i {
  transition: color 0.3s; }

table.price i.fa-cart-plus {
  color: #F39A33; }

table.price i.fa-check {
  color: green; }

/* Моргание */
.blink {
  animation: blink 1s step-end infinite; }

@keyframes blink {
  67% {
    opacity: 0; } }

/* Стили для Media Manager */
img.fr-dii.fr-fir {
  float: right;
  margin: 5px 0 5px 5px; }

img.fr-dib {
  display: block;
  float: none;
  margin: 5px auto; }
