@import "https://fonts.googleapis.com/css?family=Open+Sans:400,400i,700,700i&subset=cyrillic";

@font-face {
  font-family: "Bebas Neue Book";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/bebasneuebook.eot") format("embedded-opentype"), url("../fonts/bebasneuebook.woff") format("woff"), url("../fonts/bebasneuebook.svg") format("svg")
}

@font-face {
  font-family: 'PragmaticaLightC';
  src: url('../fonts/hinted-PragmaticaLightC-Bold.eot');
  src: url('../fonts/hinted-PragmaticaLightC-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/hinted-PragmaticaLightC-Bold.woff') format('woff'),
  url('../fonts/hinted-PragmaticaLightC-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'PragmaticaLightC';
  src: url('../fonts/hinted-PragmaticaLightC.eot');
  src: url('../fonts/hinted-PragmaticaLightC.eot?#iefix') format('embedded-opentype'),
  url('../fonts/hinted-PragmaticaLightC.woff') format('woff'),
  url('../fonts/hinted-PragmaticaLightC.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}